import React, { useEffect, useState } from 'react';
import { registerUserManual } from '../../../api/userService';
import { Col, Row } from 'react-bootstrap';

const RegisterUserForm = () => {
  const [formData, setFormData] = useState({
    email: '',
    first_name: '',
    password: '',
    last_name_paternal: '',
    com_id_user: '',
    date_birth: '',
    phone_personal: '',
  });

  useEffect(() => {
    const generateComIdUser = () => {
      const date = new Date();
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      const randomNum = Math.floor(Math.random() * 1000); // Número aleatorio entre 0 y 999
      return `UserManual-${day}-${month}-${year}-${randomNum}`;
    };

    setFormData((prevData) => ({
      ...prevData,
      com_id_user: generateComIdUser(),
    }));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await registerUserManual(formData);
      alert('User registered successfully!');
    } catch (error) {
      console.error(error);
      alert(error?.response?.data?.message);
    }
    
  };

  return (
    <div className="w-100 px-3 mx-auto mt-4">
      <div className="card">
        <div className="card-header bg-primary text-white">Registro de usuarios</div>
        <div className="card-body">
          <form onSubmit={handleSubmit} autoComplete="off">
            <Row className='mb-3'>
              <Col>
                <div className="form-floating">
                  <input
                    className="form-control"
                    style={{ fontSize: '13px' }}
                    type="text"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChange}
                    required
                  />
                  <label>Primer Nombre:</label>
                </div>
              </Col>
              <Col>
                <div className="form-floating">
                  <input
                    className="form-control"
                    style={{ fontSize: '13px' }}
                    type="text"
                    name="last_name_paternal"
                    value={formData.last_name_paternal}
                    onChange={handleChange}
                    required
                  />
                  <label>Apellido:</label>
                </div>
              </Col>
              <Col>
                <div className="form-floating">
                  <input
                    className="form-control"
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    style={{ fontSize: '13px' }}
                    id="email"
                  />
                  <label htmlFor='email'>Email:</label>
                </div>
              </Col>
              <Col>
                <div className="form-floating">
                  <input
                    className="form-control"
                    style={{ fontSize: '13px' }}
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                    autoComplete="off"
                  />
                  <label>Contraseña:</label>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-floating">
                  <input
                    className="form-control"
                    style={{ fontSize: '13px' }}
                    type="text"
                    name="com_id_user"
                    value={formData.com_id_user}
                    onChange={handleChange}
                    required
                  />
                  <label>Identificador Interno:</label>
                </div>
              </Col>
              <Col>
                <div className="form-floating">
                  <input
                    className="form-control"
                    style={{ fontSize: '13px' }}
                    type="date"
                    name="date_birth"
                    value={formData.date_birth}
                    onChange={handleChange}
                  />
                  <label>Fecha Nacimiento:</label>
                </div>
              </Col>
              <Col>
                <div className="form-floating">
                  <input
                    className="form-control"
                    style={{ fontSize: '13px' }}
                    type="text"
                    name="phone_personal"
                    value={formData.phone_personal}
                    onChange={handleChange}
                  />
                  <label>Telefono Personal:</label>
                </div>
              </Col>
              <Col>
                <div className="form-floating" style={{ fontSize: '13px' }}>
                  <select
                    name="role"
                    onChange={handleChange}
                    value={formData.role}
                    className="form-select"
                    style={{ fontSize: 'inherit' }}
                  >
                    <option value="">Selecciona el Role</option>
                    <option value="insured">Asegurado</option>
                    <option value="executive">Ejecutivo</option>
                    <option value="admin">Administrador</option>
                    <option value="operator">Operador</option>
                  </select>
                  <label htmlFor="type">Role</label>
                </div>
              </Col>
            </Row>
            <button type="submit" className="btn btn-primary me-3">Registrar</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterUserForm;
