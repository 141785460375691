import React, { useEffect, useRef, useState } from 'react';
import { Modal, Button, Row, Col, Nav, Table, Card } from 'react-bootstrap';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import Swal from 'sweetalert2';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { pdfLogo } from '../../global';
import { handleDeletePDF, openPdf } from '../../features/pdfs/functionsPdf';
import { attachPDFSubgroup, getSubgroupByPolicyId, updateSubgroup } from '../../api/SubgroupService';
import { deletePDF } from '../../api/PdfService';
import { attachPDFPolicyHolder, getPolicyHolderById, updateHolder } from '../../api/PolicyHolderService';
import withReactContent from 'sweetalert2-react-content';

function ModalEditPolicyHolder({ showModal, onClose, data }) {
    console.log('data id', data?.policy_holder.id_policy_holder)
    const MySwal = withReactContent(Swal);

    const [policy_holder, setPolicy_holder] = useState(data?.policy_holder);
    const [formData, setFormData] = useState(data?.policy_holder);
    const [actualPdfs, setActualPdfs] = useState([])
    const [pdfData, setPdfData] = useState([]);
    const [edit, setEdit] = useState(false);
    const enableEditing = () => { setEdit(true); };
    const fileInputRef = useRef(null); //Ref del input
    const cancelEdit = () => { setEdit(false); setPdfData([]); };

    useEffect(() => {
        setActualPdfs([...data.policy_holder.pdfs]);
    }, [data.policy_holder.pdfs]);

    const categories = [
        //Fisica
        'RFC',
        //Moral
        'CSF',
        'Acta_Constitutiva',
        'Poder',
        //Ambos
        'Comprobante_Domicilio',
        'Identificacion',
        'Otros',
    ];

    const handleChange = (e) => {
        const upperCaseFields = ['rfc', 'business_name', 'fiscal_address'];
        const { name, value } = e.target;
        const newValue = upperCaseFields.includes(name) ? value.toUpperCase() : value;
        setFormData(prevState => ({
            ...prevState,
            [name]: newValue
        }));
    };
    const handleFileChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        const updatedPdfData = selectedFiles.map((file) => ({
            file,
        }));
        // Actualizamos el estado con los archivos seleccionados
        setPdfData((prevPdfData = []) => [...prevPdfData, ...updatedPdfData]);
        console.log('pdfData', pdfData)
        console.log('pdfData', updatedPdfData)

        // Reseteamos el valor del input
        if (fileInputRef.current) {
            fileInputRef.current.value = ""; // Limpiar el input de archivos
        }
    };
    const handleCategoryChange = (index, selectedCategory) => {
        const updatedPdfData = [...pdfData];
        updatedPdfData[index].category = selectedCategory;
        setPdfData(updatedPdfData);
    };
    const handleRemoveFile = (index) => {
        const updatedPdfData = [...pdfData];
        updatedPdfData.splice(index, 1); // Eliminar el archivo en el índice dado
        setPdfData(updatedPdfData);
    };
    const handleDeletePDF = async (pdfId) => {
        try {
            const result = await MySwal.fire({
                title: '¿Eliminar PDF?',
                text: 'Esta acción no se puede deshacer.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Eliminar',
                cancelButtonText: 'Cancelar',
            });

            if (result.isConfirmed) {
                await deletePDF(pdfId);
                handleUpdatePdfs();

                MySwal.fire({
                    icon: 'success',
                    title: 'PDF eliminado',
                    timer: 1500,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            MySwal.fire({
                icon: 'error',
                title: 'Error al eliminar',
                text: 'No se pudo eliminar el PDF.',
                footer: `<pre>${error.response?.data?.exception || 'Error desconocido'}</pre>`,
            });
        }
    };
    const openPdf = (pdfUrl) => {
        const newWindow = window.open(pdfUrl);
        if (newWindow) {
            newWindow.onload = () => {
                newWindow.fetch(pdfUrl, {
                });
            };
        }
    };

    const handleUpdatePdfs = async () => {

        const response = await getPolicyHolderById(data?.policy_holder.id_policy_holder);
        setActualPdfs(response?.holder.pdfs)
        console.log('res update', response.holder.pdfs)
    }
    const handleSubmitFiles = async () => {
        try {
            // Crear un objeto FormData
            const formData = new FormData();

            // Agregar los archivos organizados por categorías al FormData
            pdfData.forEach(({ category, file }) => {
                if (category && file) {
                    formData.append(`${category}[]`, file); // Agrupamos los archivos bajo su categoría
                }
            });

            // Leer el contenido de FormData
            formData.forEach((value, key) => {
                console.log(key, value);
            });

            // Enviar el FormData a la API
            const response = await attachPDFPolicyHolder(data?.policy_holder.id_policy_holder, formData);
            console.log('response', response);
            handleUpdatePdfs();
            setPdfData([])
        } catch (error) {
            console.error('error', error);
        }
    };
    const handleSubmit = async () => {
        try {
            // Actualizar datos del subgrupo
            const responseUpdate = await updateHolder(data?.policy_holder.id_policy_holder, formData);
            console.log('Contratante actualizado:', responseUpdate);
            if (pdfData?.length > 0) {
                handleSubmitFiles()
            }
            // Volver a cargar los subgrupos para reflejar los cambios
            await handleUpdatePdfs();

            // Mostrar SweetAlert de éxito
            Swal.fire({
                icon: 'success',
                title: 'Contratante guardado correctamente',
                showConfirmButton: false,
                timer: 1500,
            });

            // Resetear el estado de edición y archivo seleccionado
        } catch (error) {
            console.error('Error al guardar el Contratante:', error);

            // Mostrar SweetAlert de error
            Swal.fire({
                icon: 'error',
                title: 'Error al guardar el Contratante',
                text: 'Por favor, verifica los datos e intenta nuevamente.',
                footer: error?.response?.data?.message ?? 'error'
            });
        }
    };

    return (
        <Modal show={showModal} onHide={onClose} size='lg' >
            <Modal.Header closeButton>
                <Modal.Title>Editar Contratante</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container py-3">
                    {/* Sección: Información del Contratante */}
                    <Card className="mb-4">
                        <Card.Header>
                            <h5>Información del Contratante</h5>
                        </Card.Header>
                        <Card.Body>
                            <Row className="g-3">
                                <Col md={6}>
                                    <div className="form-floating">
                                        <input
                                            type="text"
                                            name="business_name"
                                            value={formData.business_name}
                                            onChange={handleChange}
                                            className="form-control text-uppercase"
                                            placeholder="Nombre"
                                            disabled={!edit}
                                        />
                                        <label>Nombre</label>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className="form-floating">
                                        <select
                                            name="entityType"
                                            value={formData.entityType}
                                            onChange={handleChange}
                                            className="form-select"
                                            disabled={!edit}
                                        >
                                            <option value="">Selecciona Persona</option>
                                            <option value="Persona Fisica">Persona Física</option>
                                            <option value="Persona Moral">Persona Moral</option>
                                        </select>
                                        <label>Entidad</label>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className="form-floating">
                                        <input
                                            type="text"
                                            name="rfc"
                                            value={formData.rfc}
                                            onChange={handleChange}
                                            className="form-control text-uppercase"
                                            placeholder="RFC"
                                            disabled={!edit}
                                        />
                                        <label>RFC</label>
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className="form-floating">
                                        <input
                                            type="text"
                                            name="fiscal_address"
                                            value={formData.fiscal_address}
                                            onChange={handleChange}
                                            className="form-control text-uppercase"
                                            placeholder="Dirección"
                                            disabled={!edit}
                                        />
                                        <label>Dirección</label>
                                    </div>
                                </Col>
                                {/* Sección: Archivos */}
                                <Col md={12}>
                                    <h5>Agregar Archivos</h5>
                                    <div>
                                        <input
                                            ref={fileInputRef}
                                            className="form-control mb-3"
                                            type="file"
                                            accept=".pdf"
                                            multiple
                                            onChange={handleFileChange}
                                            disabled={!edit}
                                        />
                                        {pdfData &&
                                            pdfData.map((pdf, index) => (
                                                <div key={index} className="d-flex align-items-center mb-2">
                                                    <p
                                                        className="m-0 me-3"
                                                        style={{
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            width: '70%',
                                                        }}
                                                    >
                                                        {pdf.file.name}
                                                    </p>
                                                    <select
                                                        value={pdf.category}
                                                        className="form-select me-3"
                                                        onChange={(e) =>
                                                            handleCategoryChange(index, e.target.value)
                                                        }
                                                    >
                                                        <option value="">Selecciona</option>
                                                        {categories.map((category, i) => (
                                                            <option key={i} value={category}>
                                                                {category}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <Button
                                                        variant="danger"
                                                        size="sm"
                                                        onClick={() => handleRemoveFile(index)}
                                                    >
                                                        Eliminar
                                                    </Button>
                                                </div>
                                            ))}
                                    </div>
                                </Col>
                            </Row>

                            {/* Botones de Acción */}
                            <div className="d-flex justify-content-end gap-2">
                                {!edit ? (
                                    <Button
                                        onClick={enableEditing}
                                        variant="primary"
                                    >
                                        Editar
                                    </Button>
                                ) : (
                                    <>
                                        <Button onClick={handleSubmit} variant="success">
                                            Guardar
                                        </Button>
                                        <Button onClick={cancelEdit} variant="secondary">
                                            Cancelar
                                        </Button>
                                    </>
                                )}
                            </div>
                        </Card.Body>
                    </Card>



                    {/* Sección: Tabla de Archivos Actuales */}
                    <Card className="mb-4">
                        <Card.Header>
                            <h5>Archivos Actuales</h5>
                        </Card.Header>
                        <Card.Body>
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>PDF</th>
                                        <th>Nombre</th>
                                        <th>Categoría</th>
                                        <th>Fecha</th>
                                        <th>Acción</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {actualPdfs.map((pdf) => (
                                        <tr key={pdf.id}>
                                            <td>
                                                <a href="#" onClick={() => openPdf(pdf.public_link)}>
                                                    <img src={pdfLogo} alt="PDF" style={{ width: "28px" }} />
                                                </a>
                                            </td>
                                            <td>{pdf.file_name}</td>
                                            <td>{pdf.category}</td>
                                            <td>{new Date(pdf.created_at).toLocaleDateString()}</td>
                                            <td>
                                                <Button
                                                    variant="danger"
                                                    size="sm"
                                                    onClick={() => handleDeletePDF(pdf.id, pdf.pdfable_id)}
                                                >
                                                    Eliminar
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>


                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
export default ModalEditPolicyHolder;
