// helpers.js

// Initial data for Certificates
export const initialCertificateData = {
  organization_id: '',
  policy_holder_id: '',
  policy_id: '',
  subgroup_id: '',
  certificate_number: '',
  status: 'pending',
  details: '',
  email: '',
  work_email: '',
  first_name: '',
  last_name_paternal: '',
  last_name_maternal: '',
  date_birth: '',
  current_antiquity: '',
  total_antiquity: '',
  gender: '',
  rfc: '',
  curp: '',
  phone_office: '',
  phone_personal: '',
  admin_email: '',

  Certificado: [],
  Credencial_Digital: [],
  Carta_Antiguedad: [],
  Otros: [],
};



// Initial data for Policies
export const initialPolicyData = {
  policy_number: '',
  policy_branch: '',
  concept: '',
  start_date_coverage: '',
  end_date_coverage: '',
  total: '',
  pool: '',
  policy_type: '',
  executive_name: '',
  admin_email: '',
  insurer_name: '',
  net_premium: '',
  fractional_payment_surcharge: '',
  policy_fee: '',
  total_premium: '',
  payment_method: '',
  commission_percentage: '',
  bonus1: '',
  bonus2: '',
  policy_holder_id: '',
  organization_id: '',
  pdfs: []
};

export const initialDependentData = {
  first_name: '',
  last_name_paternal: '',
  last_name_maternal: '',
  work_email: '',
  rfc: '',
  curp: '',
  phone_office: '',
  phone_personal: '',
  rol: "dependent",
  relationship: '',
  date_birth: '',
  current_antiquity: '',
  total_antiquity: '',
  gender: '',
  rfc: '',
  curp: '',
  phone_office: '',
  phone_personal: '',
}

export const initialInsuredData = {
  first_name: '',
  last_name_paternal: '',
  last_name_maternal: '',
  relationship: '',
  date_birth: '',
  gender: '',
  current_antiquity: '',
  total_antiquity: '',
  email: '',
  work_email: '',
  rfc: '',
  curp: '',
  phone_office: '',
  phone_personal: '',
  rol: "",
  subgroup_id: '',
  certificate_id: '',
}


export const initialProcedureData = {
  internal_folio: '',
  organization_id: '',
  policy_holder_id: '',
  policy_id: '',
  insured_id: '',
  insurer_id: '',
  executive_id: '',
  contact_id: '',
  procedure_type: '',
  insurer_folio: '',
  status: '',
  details: '',
  comments: '',
  expected_date: '',
  client_request_date: '',
  insurer_request_date: '',
  endorsement_number: '',
  request_method: '',
  movement_type: '',
  policy_branch: '',
  policy_type: '',
  Otros: [],
  pdfs_receipts: [],
}