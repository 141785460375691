import React, { useMemo, useState, useEffect } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
} from 'material-react-table';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Box, Button, lighten, IconButton } from '@mui/material';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import { findPolicyByOrganization } from '../../api/OrganizationService.js';
import { useDispatch } from 'react-redux';
import { openModal } from '../../features/modalSlice/modalPolicySlice.js';
import ModalActionsPolicies from './ModalActionsPolicies.js';
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import { pdfLogo } from "../../global";
import { Search } from '@mui/icons-material';

const MainTable = ({ organization }) => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchData();
  }, [organization]);

  const fetchData = async () => {
    try {
      const response = await findPolicyByOrganization(organization);
      setData(response);
    } catch (error) {
      console.error(error);
    }
  };
  const formatDateRange = (startDate, endDate) => {
    if (!startDate || !endDate) return '';
    const startFormatted = format(parseISO(startDate), 'dd MMM yy', { locale: es }).toUpperCase();
    const endFormatted = format(parseISO(endDate), 'dd MMM yy', { locale: es }).toUpperCase();
    return `${startFormatted} - ${endFormatted}`;
  };
  const columns = useMemo(
    () => [
      {

        id: 'details',
        header: 'Detalles',
        columns: [
          { accessorKey: 'insurer.commercial_name', header: 'Aseguradora', size: 50 },

          { accessorKey: 'policy_number', header: 'Poliza', size: 50 },
          { accessorKey: 'policy_type', header: 'Tipo', size: 50 },
          { accessorKey: 'policy_holder.business_name', header: 'Contratante', size: 50 },
          { accessorKey: 'concept', header: 'Concepto', size: 50 },

          {
            id: 'date_range',
            header: 'Fecha de Cobertura',
            size: 100,
            accessorFn: (row) => {
              const startDate = row?.start_date_coverage;
              const endDate = row?.end_date_coverage;
              return formatDateRange(startDate, endDate);
            },
          },
          {

            id: 'pdf_declaration',
            header: 'Caratula',
            size: 50,
            accessorFn: (row) => {
              const pdfs = row.pdfs || [];

              const filteredPdfs = pdfs.filter(pdf => pdf.category === 'pdf_declaration' || pdf.category === 'Caratula');
              if (filteredPdfs.length > 0) {
                return filteredPdfs.map(pdf => (
                  <a key={pdf.id} href={pdf.public_link} target="_blank" rel="noopener noreferrer">
                    <img src={pdfLogo} alt="PDF" style={{ width: '40px', marginRight: '10px' }} />
                  </a>
                ));
              } else {
                return 'NA';
              }
            },
          },
          {
            id: 'pdf_policy_bill',
            header: 'Factura',
            size: 50,
            accessorFn: (row) => {
              const pdfs = row.pdfs || [];
              const filteredPdfs = pdfs.filter(pdf => pdf.category === 'Factura');
              if (filteredPdfs.length > 0) {
                return filteredPdfs.map(pdf => (
                  <a key={pdf.id} href={pdf.public_link} target="_blank" rel="noopener noreferrer">
                    <img src={pdfLogo} alt="PDF" style={{ width: '40px', marginRight: '10px' }} />
                  </a>
                ));
              } else {
                return 'NA';
              }
            },
          }
        ],
      },
    ],
    []
  );

  const handleRowClick = (row) => {
    dispatch(openModal(row.original));
  };

  const table = useMaterialReactTable({
    columns: columns[0].columns,
    data,
    enableColumnFilterModes: false,
    enableColumnOrdering: true,
    enableGrouping: true,
    enableColumnPinning: true,
    enableFacetedValues: true,
    enableRowActions: true,
    renderRowActions: ({ row }) => (
      <Box>
        <IconButton color="primary" onClick={() => handleRowClick(row)}>
          <Search fontSize="large" />
        </IconButton>
      </Box>
    ),
    muiFilterTextFieldProps: ({ column }) => ({
      label: `${column.columnDef.header}`,
    }),
    enableRowSelection: false,
    initialState: {
      showColumnFilters: false,
      showGlobalFilter: true,
    },
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    muiSearchTextFieldProps: {
      placeholder: 'Busqueda Global',
      size: 'medium',
      variant: 'outlined',
    },
    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [10, 20, 30],
      shape: 'rounded',
      variant: 'outlined',
    },
    columnPinning: {
      left: ['mrt-row-actions'],
    },
    renderTopToolbar: ({ table }) => {
      const handleDeactivate = () => {
        table.getSelectedRowModel().flatRows.forEach((row) => {
          alert('Desactivando ' + row.getValue('policy_number'));
        });
      };

      const handleActivate = () => {
        table.getSelectedRowModel().flatRows.forEach((row) => {
          alert('Activando ' + row.getValue('policy_number'));
        });
      };

      const handleContact = () => {
        table.getSelectedRowModel().flatRows.forEach((row) => {
          alert('Contactando ' + row.getValue('policy_number'));
        });
      };

      return (
        <Box
          sx={(theme) => ({
            backgroundColor: lighten(theme.palette.background.default, 0.05),
            display: 'flex',
            gap: '0.5rem',
            p: '8px',
            justifyContent: 'space-between',
          })}
        >
          <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            <MRT_GlobalFilterTextField table={table} />
            <MRT_ToggleFiltersButton table={table} />
          </Box>
          <Box>
            <Box sx={{ display: 'flex', gap: '0.5rem' }}>
              <Button
                color="error"
                disabled={!table.getIsSomeRowsSelected()}
                onClick={handleDeactivate}
                variant="contained"
              >
                Desactivar
              </Button>
              <Button
                color="success"
                disabled={!table.getIsSomeRowsSelected()}
                onClick={handleActivate}
                variant="contained"
              >
                Activar
              </Button>
              <Button
                color="info"
                disabled={!table.getIsSomeRowsSelected()}
                onClick={handleContact}
                variant="contained"
              >
                Contactar
              </Button>
            </Box>
          </Box>
        </Box>
      );
    },
  });

  return (
    <>
      <MaterialReactTable
        table={table}
        columns={columns[0].columns.map((col) => ({
          ...col,
        }))}
      />
      <ModalActionsPolicies />
    </>
  );
};

const MainTableWithLocalizationProvider = ({ selectedOrganization }) => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <MainTable organization={selectedOrganization} />
  </LocalizationProvider>
);

export default MainTableWithLocalizationProvider;
