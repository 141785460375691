export const types = [
    { name: 'Vida Grupo', type: 'LC', branch: 'GMM',renewable: '', administration_type: ''},
    { name: 'Vida Individual', type: 'LP', branch: 'GMM',renewable: '', administration_type: ''},
    { name: 'GMM Colectivo', type: 'LC', branch: 'GMM',renewable: '', administration_type: ''},
    { name: 'GMM Individual', type: 'LP', branch: 'GMM', renewable: 'Renovable', administration_type: 'A Detalle'},
    { name: 'AP', type: 'LC', branch: 'GMM',renewable: '', administration_type: ''}, //Accidentes Personales
    { name: 'AP Individual', type: 'LP', branch: 'GMM',renewable: '', administration_type: ''},
    { name: 'Viajero', type: 'LP', branch: 'GMM',renewable: '', administration_type: ''},
    { name: 'Otros Beneficios', type: 'LC', branch: 'GMM',renewable: '', administration_type: ''},
    { name: 'Otros Daños', type: 'LC', branch: 'Daños',renewable: '', administration_type: ''},
    { name: 'Multiple Empresarial', type: 'LC', branch: 'Daños',renewable: '', administration_type: ''},
    { name: 'Transporte', type: 'LC', branch: 'Daños',renewable: '', administration_type: ''},
    { name: 'RC', type: 'LC', branch: 'Daños',renewable: '', administration_type: ''},
    { name: 'Obra Civil', type: 'LC', branch: 'Daños',renewable: '', administration_type: ''},
    { name: 'Montaje', type: 'LC', branch: 'Daños',renewable: '', administration_type: ''},
    { name: 'Hogar', type: 'LP', branch: 'Daños',renewable: '', administration_type: ''},
    { name: 'Autos', type: 'LP', branch: 'Autos',renewable: '', administration_type: ''},
    { name: 'Flotillas', type: 'LC', branch: 'Autos',renewable: '', administration_type: ''},
];
