import React from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { es } from 'date-fns/locale';
import { format } from 'date-fns'; // Importa la función 'format' de date-fns

const DateField = ({ label, name, value, onChange }) => {
  // Valida el valor para evitar errores
  const parsedValue = value ? new Date(value) : null;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
      <div className="form-floating" style={{ fontSize: '13px' }}>
        <DesktopDatePicker
          value={isNaN(parsedValue) ? null : parsedValue || new Date()} // Maneja valores inválidos y establece la fecha por defecto actual
          onChange={(newValue) => {
            onChange(name, newValue);
          }}
          format="dd-MM-yyyy" // Especifica el formato de retorno
          views={["day", "month", "year"]}

          slotProps={{
            textField: {
              size: 'small',
              style: {
                width: '100%',
              },
              label,
              name,
              placeholder: 'dd/mm/yyyy', // Placeholder
            },
          }}
        />
      </div>
    </LocalizationProvider>

  );
};

export default DateField;
