import React, { useEffect, useState } from 'react';
import { Modal, Button, Row, Col, Nav, Table } from 'react-bootstrap';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import Swal from 'sweetalert2';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { pdfLogo } from '../../global';
import { handleDeletePDF, openPdf } from '../../features/pdfs/functionsPdf';
import { attachPDFSubgroup, getSubgroupByPolicyId, updateSubgroup } from '../../api/SubgroupService';
import { deletePDF } from '../../api/PdfService';

function ModalEditSubgroup({ showModal, onClose, data }) {
    console.log('data', data?.subgroups)

    const [subgroups, setSubgroups] = useState(data?.subgroups);
    const [selectedSubgroupId, setSelectedSubgroupId] = useState("");
    const [selectedSubgroup, setSelectedSubgroup] = useState("");
    const [formSubgroupData, setFormSubgroupData] = useState("");
    const [filesSubgroup, setFilesSubgroup] = useState(null);
    const [fileSubgroup, setFileSubgroup] = useState(null);

    const [edit, setEdit] = useState(false);
    const cancelEdit = () => { setEdit(false); setFileSubgroup(null); };
    const enableEditing = () => { setEdit(true); };

    useEffect(() => {
        if (data?.subgroups?.length > 0) {
            const firstSubgroup = data.subgroups[0];
            setSelectedSubgroupId(firstSubgroup.id_subgroup);
            setSelectedSubgroup(firstSubgroup);
            setFileSubgroup(firstSubgroup.pdfs);
            console.log(selectedSubgroup.pdfs);
            setFormSubgroupData({
                subgroup_name: firstSubgroup.subgroup_name || "",
                subgroup_number: firstSubgroup.subgroup_number || "",
                hospital_level: firstSubgroup.hospital_level || "",
            });
        }
    }, [data]);

    const fetchSubgroups = async () => {
        try {
            const response = await getSubgroupByPolicyId(data?.id_policy);
            setSubgroups(response);

            // Mantener el subgrupo seleccionado
            const updatedSubgroup = response.find(subgroup => subgroup.id_subgroup === selectedSubgroupId);
            if (updatedSubgroup) {
                setSelectedSubgroup(updatedSubgroup);
                setFileSubgroup(updatedSubgroup.pdfs);
                setFormSubgroupData({
                    subgroup_name: updatedSubgroup.subgroup_name || "",
                    subgroup_number: updatedSubgroup.subgroup_number || "",
                    hospital_level: updatedSubgroup.hospital_level || "",
                });
            }
        } catch (error) {
            console.error('Error al cargar subgrupos:', error);
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormSubgroupData({ ...formSubgroupData, [name]: value });
    };
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFileSubgroup(selectedFile);
        console.log('Archivo seleccionado:', selectedFile);
    };



    // Cambiar el subgrupo seleccionado
    const handleSubgroupClick = async (id) => {
        setSelectedSubgroupId(id); // Cambiar al subgrupo seleccionado
        setSelectedSubgroup(id);
        // Encontrar el endoso seleccionado dentro de los datos de endosos
        const selectedSubgroup = data?.subgroups.find(subgroup => subgroup.id_subgroup === id);
        setSelectedSubgroupId(selectedSubgroup.pdfs);
        console.log('selectedSubgroup', selectedSubgroup)
        setSelectedSubgroup(selectedSubgroup);

        if (selectedSubgroup) {
            setFormSubgroupData({
                subgroup_number: selectedSubgroup.subgroup_number || "",
                subgroup_name: selectedSubgroup.subgroup_name || "",
                hospital_level: selectedSubgroup.hospital_level || "",
                pdfs: selectedSubgroup.pdfs || [],
            });
        }
    };
    const saveSubgroup = async () => {
        try {
            if (fileSubgroup) {
                const formData = new FormData();
                formData.append('pdfs', fileSubgroup);

                // Adjuntar PDF al subgrupo
                const responseFileSubgroup = await attachPDFSubgroup(selectedSubgroupId, formData);
                console.log('PDF subido:', responseFileSubgroup);
            }

            // Actualizar datos del subgrupo
            const responseUpdateSubgroup = await updateSubgroup(selectedSubgroupId, formSubgroupData);
            console.log('Subgrupo actualizado:', responseUpdateSubgroup);

            // Volver a cargar los subgrupos para reflejar los cambios
            await fetchSubgroups();

            // Mostrar SweetAlert de éxito
            Swal.fire({
                icon: 'success',
                title: 'Subgrupo guardado correctamente',
                showConfirmButton: false,
                timer: 1500,
            });

            // Resetear el estado de edición y archivo seleccionado
            cancelEdit();
        } catch (error) {
            console.error('Error al guardar el subgrupo:', error);

            // Mostrar SweetAlert de error
            Swal.fire({
                icon: 'error',
                title: 'Error al guardar el subgrupo',
                text: 'Por favor, verifica los datos e intenta nuevamente.',
                footer: error?.response?.data?.message ?? 'error'
            });
        }
    };

    const handleDeletePDF = async (pdfId, subgroupId) => {
        try {
            // Llamada a la API para eliminar el PDF
            await deletePDF(pdfId, subgroupId);
            Swal.fire({
                icon: 'success',
                title: 'PDF eliminado correctamente',
                showConfirmButton: false,
                timer: 1500,
            });

            // Actualiza los subgrupos después de la eliminación
            await fetchSubgroups();
        } catch (error) {
            console.error('Error al eliminar el PDF:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error al eliminar el PDF',
                text: 'Por favor, intenta nuevamente.',
            });
        }
    };

    return (
        <Modal show={showModal} onHide={onClose} size='lg' >
            <Modal.Header closeButton>
                <Modal.Title>Selecciona un Subgrupo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    {/* Lista de subgrupos */}
                    <Col xs={3} className="border-right" style={{ borderRight: "3px solid #ccc" }}>
                        <Nav className="flex-column">
                            <div style={{ maxHeight: "400px", overflowY: "scroll" }}>
                                {subgroups.map((subgroup) => (
                                    <Nav.Link
                                        key={subgroup.id_subgroup}
                                        onClick={() => handleSubgroupClick(subgroup.id_subgroup)}
                                        active={selectedSubgroup === subgroup.id_subgroup}
                                        style={{
                                            cursor: "pointer",
                                            border: "1px solid #ccc",
                                            padding: "10px",
                                            marginBottom: "5px",
                                            borderRadius: "5px",
                                            backgroundColor:
                                                selectedSubgroup === subgroup.id_subgroup
                                                    ? "#d1e7dd"
                                                    : "transparent",
                                            fontWeight:
                                                selectedSubgroup === subgroup.id_subgroup
                                                    ? "bold"
                                                    : "normal",
                                        }}
                                    >
                                        Subgrupo: {subgroup.subgroup_name}
                                    </Nav.Link>
                                ))}
                            </div>
                        </Nav>
                    </Col>

                    {/* Formulario para editar el subgrupo */}
                    <Col xs={9}>
                        <div style={{ padding: "15px" }}>
                            <h5>
                                Información del subgrupo:{" "}
                                {selectedSubgroup.subgroup_name}
                            </h5>
                            <Row className="mb-3">
                                <Col xs={4}>
                                    <div className="form-floating">
                                        <input
                                            type="text"
                                            name="subgroup_name"
                                            value={formSubgroupData.subgroup_name}
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder="Nombre del Subgrupo"
                                            disabled={!edit}
                                        />
                                        <label>Nombre del Subgrupo</label>
                                    </div>
                                </Col>
                                <Col xs={4}>
                                    <div className="form-floating">
                                        <input
                                            type="text"
                                            name="subgroup_number"
                                            value={formSubgroupData.subgroup_number}
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder="Número del Subgrupo"
                                            disabled={!edit}
                                        />
                                        <label>Número del Subgrupo</label>
                                    </div>
                                </Col>
                                <Col xs={4}>
                                    <div className="form-floating">
                                        <input
                                            type="text"
                                            name="hospital_level"
                                            value={formSubgroupData.hospital_level}
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder="Número del Subgrupo"
                                            disabled={!edit}
                                        />
                                        <label>Nivel Hospitalario</label>
                                    </div>
                                </Col>
                                {edit && (
                                    <Col>
                                        <div className="form-floating">
                                            <input
                                                type="file"
                                                name="pdf"
                                                accept="application/pdf"
                                                onChange={handleFileChange}
                                                multiple={false}
                                                className="form-control"
                                                placeholder="PDFs"
                                                disabled={!edit}
                                            />
                                            <label>Presentacion Nueva</label>
                                        </div>
                                    </Col>
                                )}
                            </Row>
                            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                {!edit ? (
                                    <Button onClick={enableEditing} className="btn btn-primary btn-md">
                                        <EditRoundedIcon />
                                    </Button>
                                ) : (
                                    <>
                                        <Button onClick={saveSubgroup} className="btn btn-success btn-md">
                                            <SaveIcon />
                                        </Button>
                                        <Button onClick={cancelEdit} className="btn btn-secondary btn-md">
                                            <CancelIcon />
                                        </Button>
                                    </>
                                )}
                            </div>
                            <Row>
                                <h5>Archivos Actuales</h5>

                                <Table striped bordered hover size="sm" responsive>
                                    <thead>
                                        <tr>
                                            <th>PDF</th>
                                            <th>Nombre</th>
                                            <th>Categoria</th>
                                            <th>Fecha</th>
                                            <th>Eliminar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedSubgroup?.pdfs?.length > 0 ? (
                                            selectedSubgroup?.pdfs?.map((pdf) => (
                                                <tr key={pdf.id}>
                                                    <td>
                                                        <a href="#" onClick={() => openPdf(pdf.public_link)}>
                                                            <img
                                                                src={pdfLogo}
                                                                alt="PDF"
                                                                style={{ width: '28px', marginRight: '10px' }}
                                                            />
                                                        </a>
                                                    </td>
                                                    <td>{pdf.file_name}</td>
                                                    <td>{pdf.category}</td>
                                                    <td>{new Date(pdf.created_at).toLocaleDateString()}</td>
                                                    <td>
                                                        <Button
                                                            variant="danger"
                                                            size="sm"
                                                            onClick={() => handleDeletePDF(pdf.id, pdf.pdfable_id)}
                                                        >
                                                            Eliminar
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="5">No hay PDFs asociados.</td>
                                            </tr>
                                        )}

                                    </tbody>
                                </Table>
                            </Row>


                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
export default ModalEditSubgroup;
