import React from 'react';
import { Modal, Button, Form, Table, Accordion } from 'react-bootstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { attachFilesInsured, updateInsured } from '../../../api/InsuredService';
import { deletePDF } from '../../../api/PdfService';
import { pdfLogo } from '../../../global';
import { RenderDropzone } from '../../../features/pdfs/functionsPdf';
import DateField from '../../Helpers/DateField';
import { format } from 'date-fns';

const ModalEditInsured = ({ showModal, handleClose, insured, updateList }) => {
    console.log('asegurado seleccionado', insured)
    const MySwal = withReactContent(Swal);
    const [formData, setFormData] = React.useState(insured);
    const [showCancellation, setShowCancellation] = React.useState(false);
    const [dateCancellation, setDateCancellation] = React.useState(new Date().toString());

    React.useEffect(() => {
        setFormData(insured);
    }, [insured]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        console.log('name', value)
        setFormData({ ...formData, [name]: value });
    };
    const handleChangeStatus = (event) => {
        const { name, value } = event.target;
        if (value == 'Baja') {
            setShowCancellation(true)
            handleClose()
        } else {
            setFormData({ ...formData, [name]: value });
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formType = e.target.getAttribute('data-form-type'); // Obtiene el tipo de formulario

        try {
            if (formType === 'uploadFiles') {
                // Lógica para subir archivos
                const requiredFiles = ['Carta_Antiguedad', 'Endoso'];
                const missingFiles = requiredFiles.filter(file => !formData[file] || formData[file].length === 0);

                if (missingFiles.length > 0) {
                    MySwal.fire({
                        icon: 'error',
                        title: 'Archivos faltantes',
                        text: `Faltan los siguientes archivos requeridos: ${missingFiles.join(', ')}`,
                        confirmButtonText: 'Cerrar'
                    });
                    return;
                }
                const updatedFormData = {
                    ...formData,
                    status: dateCancellation ? 'Baja' : formData.status,
                    policy_cancellation_date: dateCancellation
                        ? format(new Date(dateCancellation), 'yyyy-MM-dd')
                        : formData.policy_cancellation_date,
                };
                await attachFilesInsured(insured.id_insured, updatedFormData);
            } else if (formType === 'update') {
                // Lógica para actualizar asegurado

                await updateInsured(insured.id_insured, formData);
            }
            MySwal.fire({
                title: '¡Éxito!',
                text: 'El Asegurado se ha actualizado correctamente.',
                icon: 'success',
                confirmButtonText: 'Cerrar'
            }).then(() => {
                updateList()
                setShowCancellation(false)
            });
            handleClose();
        } catch (error) {
            MySwal.fire({
                icon: 'error',
                title: '¡Error!',
                text: 'Ha ocurrido un error al actualizar el Asegurado.',
                footer: `<pre>${error.response.data.exception || 'Error desconocido'}</pre>`,
            });
        }
    };
    /* const handleSubmitCancellation = async (e) => {
        e.preventDefault();
        const formData = new FormData();

        // Formatea la fecha correctamente
        const formattedDate = format(new Date(formDataCancellation?.policy_cancellation_date), 'yyyy-MM-dd');

        // Copia el estado actual y actualiza con los nuevos datos
        formData.append('policy_cancellation_date', formattedDate);
        formData.append('status', 'Baja');

        for (const key in formDataCancellation) {
            if (Object.hasOwnProperty.call(formDataCancellation, key)) {
                const value = formDataCancellation[key];
                if (Array.isArray(value)) {
                    value.forEach(file => formData.append(key, file));
                } else {
                    formData.append(key, value);
                }
            }
        }
        formData.forEach((value, key) => {
            console.log(`${key}: ${value}`);
        });
        // Validar que los archivos requeridos estén presentes
        const requiredFiles = ['Carta_Antiguedad', 'Endoso'];

        const missingFiles = requiredFiles.filter(file => !formDataCancellation[file] || formDataCancellation[file].length === 0);

        if (missingFiles.length > 0) {
            // Mostrar mensaje de error si faltan archivos
            MySwal.fire({
                icon: 'error',
                title: 'Archivos faltantes',
                text: `Faltan los siguientes archivos requeridos: ${missingFiles.join(', ')}`,
                confirmButtonText: 'Cerrar'
            });
            return;
        }

        // Si la validación pasa, continúa con el envío
        try {
            await updateInsured(insured.id_insured, formData);
            console.log('formDataCancellation', formData)
            MySwal.fire({
                title: '¡Éxito!',
                text: 'El Asegurado se ha actualizado correctamente.',
                icon: 'success',
                confirmButtonText: 'Cerrar'
            }).then(() => {
                updateList();
            });
            setShowCancellation(false);
        } catch (error) {
            MySwal.fire({
                icon: 'error',
                title: '¡Error!',
                text: 'Ha ocurrido un error al actualizar el Asegurado.',
                footer: `<pre>${error.response?.data?.exception || 'Error desconocido'}</pre>`,
            });
        }
    }; */

    const handleDeletePDF = async (pdfId) => {
        try {
            const result = await MySwal.fire({
                title: '¿Eliminar PDF?',
                text: 'Esta acción no se puede deshacer.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Eliminar',
                cancelButtonText: 'Cancelar',
            });

            if (result.isConfirmed) {
                await deletePDF(pdfId);
                updateList();
                handleClose();

                MySwal.fire({
                    icon: 'success',
                    title: 'PDF eliminado',
                    timer: 1500,
                    showConfirmButton: true,
                    onClose: () => console.log('El modal se cerró'),
                });
            }
        } catch (error) {
            MySwal.fire({
                icon: 'error',
                title: 'Error al eliminar',
                text: 'No se pudo eliminar el PDF.',
                footer: `<pre>${error.response?.data?.exception || 'Error desconocido'}</pre>`,
            });
        }
    };

    const openPdf = (pdfUrl) => {
        const newWindow = window.open(pdfUrl);
        if (newWindow) {
            newWindow.onload = () => {
                newWindow.fetch(pdfUrl, {
                });
            };
        }
    };
    const handleFileChange = (updatedFiles, category) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [category]: updatedFiles
        }));
        console.log(category, updatedFiles);
    };


    const handleDateChange = (name, value) => {
        if (name === 'policy_cancellation_date') {
            setDateCancellation(value)
            console.log('Fecha1: ', value)
        } else {
            setDateCancellation(value)
            console.log('Fecha2')
        }
    };

    const handleCloseCancellation = () => setShowCancellation(false);
    return (
        <div>
            <Modal size="lg" show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar asegurado</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-center fw-bold text-uppercase" style={{ fontSize: '1.2rem', color: insured.user_id ? '#28a745' : '#dc3545' }}>
                        {insured.user_id
                            ? "El asegurado ya tiene usuario en SeguWallet."
                            : "El asegurado aun no tiene cuenta en SeguWallet"}
                    </p>


                    <Form onSubmit={(e) => handleSubmit(e)} data-form-type="update">
                        <div className="row mb-3">
                            <div className="col-md-4">
                                <Form.Group controlId="formFirstName">
                                    <div className="form-floating">
                                        <Form.Control
                                            type="text"
                                            name="first_name"
                                            onChange={handleChange}
                                            value={formData.first_name || ""}

                                        />
                                        <label htmlFor="first_name">Nombre:</label>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <Form.Group controlId="formLastNamePaternal">
                                    <div className="form-floating">
                                        <Form.Control
                                            type="text"
                                            name="last_name_paternal"
                                            onChange={handleChange}
                                            value={formData.last_name_paternal || ""}

                                        />
                                        <label htmlFor="last_name_paternal">Apellido Paterno:</label>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <Form.Group controlId="formLastNameMaternal">
                                    <div className="form-floating">
                                        <Form.Control
                                            type="text"
                                            name="last_name_maternal"
                                            onChange={handleChange}
                                            value={formData.last_name_maternal || ""}

                                        />
                                        <label htmlFor="last_name_maternal">Apellido Materno:</label>
                                    </div>
                                </Form.Group>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-md-4">
                                <Form.Group controlId="formRFC">
                                    <div className="form-floating">
                                        <Form.Control
                                            type="text"
                                            name="rfc"
                                            onChange={handleChange}
                                            value={formData.rfc || ""}

                                        />
                                        <label htmlFor="rfc">RFC:</label>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <Form.Group controlId="formCURP">
                                    <div className="form-floating">
                                        <Form.Control
                                            type="text"
                                            name="curp"
                                            onChange={handleChange}
                                            value={formData.curp || ""}

                                        />
                                        <label htmlFor="curp">CURP:</label>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <Form.Group controlId="formPhoneOffice">
                                    <div className="form-floating">
                                        <Form.Control
                                            type="text"
                                            name="phone_office"
                                            onChange={handleChange}
                                            value={formData.phone_office || ""}

                                        />
                                        <label htmlFor="phone_office">Teléfono de Oficina:</label>
                                    </div>
                                </Form.Group>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-md-4">
                                <Form.Group controlId="formPhonePersonal">
                                    <div className="form-floating">
                                        <Form.Control
                                            type="text"
                                            name="phone_personal"
                                            onChange={handleChange}
                                            value={formData.phone_personal || ""}

                                        />
                                        <label htmlFor="phone_personal">Teléfono Personal:</label>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <Form.Group controlId="formRelationship">
                                    <div className="form-floating">
                                        <select
                                            name="relationship"
                                            onChange={handleChange}
                                            className="form-select"
                                            value={formData.relationship || ""}

                                        >
                                            <option value="">Selecciona la Relación</option>
                                            <option value="holder">Titular</option>
                                            <option value="spouse">Cónyuge</option>
                                            <option value="dependent">Dependiente</option>
                                        </select>
                                        <label htmlFor="relationship">Relación:</label>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <Form.Group controlId="formDateBirth">
                                    <div className="form-floating">
                                        <Form.Control
                                            type="date"
                                            name="date_birth"
                                            onChange={handleChange}
                                            value={formData.date_birth || ""}

                                        />
                                        <label htmlFor="date_birth">Fecha de Nacimiento:</label>
                                    </div>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-4">
                                <Form.Group controlId="formGender">
                                    <div className="form-floating">
                                        <Form.Control
                                            as="select"
                                            name="gender"
                                            onChange={handleChange}
                                            value={formData.gender || ""}

                                        >
                                            <option value="">Selecciona un género</option>
                                            <option value="M">Masculino</option>
                                            <option value="F">Femenino</option>
                                        </Form.Control>
                                        <label htmlFor="gender">Género:</label>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <Form.Group controlId="formCurrentAntiquity">
                                    <div className="form-floating">
                                        <Form.Control
                                            type="date"
                                            name="current_antiquity"
                                            onChange={handleChange}
                                            value={formData.current_antiquity || ""}

                                        />
                                        <label htmlFor="current_antiquity">Antigüedad Actual:</label>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-4" >
                                <Form.Group controlId="formTotalAntiquity">
                                    <div className="form-floating">
                                        <Form.Control
                                            type="date"
                                            name="total_antiquity"
                                            onChange={handleChange}
                                            value={formData.total_antiquity || ""}

                                        />
                                        <label htmlFor="total_antiquity">Antigüedad Total:</label>
                                    </div>
                                </Form.Group>
                            </div>

                        </div>
                        <div className="row mb-3">
                            <div className="col-md-4">
                                <Form.Group controlId="formEmailPersonal">
                                    <div className="form-floating">
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            onChange={handleChange}
                                            value={formData.email || ""}

                                        />
                                        <label htmlFor="email">Correo Personal:</label>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <Form.Group controlId="formWorkEmail">
                                    <div className="form-floating">
                                        <Form.Control
                                            type="email"
                                            name="work_email"
                                            onChange={handleChange}
                                            value={formData.work_email || ""}

                                        />
                                        <label htmlFor="work_email">Correo Laboral:</label>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <Form.Group controlId="formStatus">
                                    <div className="form-floating">
                                        <select
                                            name="status"
                                            onChange={handleChangeStatus}
                                            className="form-select"
                                            value={formData.status || ""}

                                        >
                                            <option value="">Estado</option>
                                            <option value="Vigente">Vigente</option>
                                            <option value="Baja">Baja</option>
                                        </select>
                                        <label htmlFor="status">Estado:</label>
                                    </div>
                                </Form.Group>
                            </div>

                        </div>
                        <div className="row mb-3">
                            <div className="col-md-4">
                                <Form.Group controlId="formEmailPersonal">
                                    <div className="form-floating">
                                        <Form.Control
                                            type="date"
                                            name="policy_cancellation_date"
                                            onChange={handleChange}
                                            value={formData.policy_cancellation_date || ""}

                                        />
                                        <label htmlFor="policy_cancellation_date">Fecha de Cancelacion :</label>
                                    </div>
                                </Form.Group>
                            </div>
                        </div>

                        <Table striped bordered hover size="sm" responsive >
                            <thead>
                                <tr>
                                    <th>PDF</th>
                                    <th>Nombre</th>
                                    <th>Categoría</th>
                                    <th>Fecha</th>
                                    <th>Eliminar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {insured.pdfs.map((pdf) => (
                                    <tr key={pdf.id}>
                                        <td>
                                            <a href="#" onClick={() => openPdf(pdf.public_link)}>
                                                <img src={pdfLogo} alt="PDF" style={{ width: '28px', marginRight: '10px' }} />
                                            </a>
                                        </td>
                                        <td>{pdf.file_name}</td>
                                        <td>{pdf.category}</td>
                                        <td>{new Date(pdf.created_at).toLocaleDateString()}</td>
                                        <td>
                                            <Button
                                                variant="danger"
                                                size="sm"
                                                onClick={() => handleDeletePDF(pdf.id, pdf.pdfable_id)}
                                            >
                                                Eliminar
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                        <Button variant="primary" type="submit">
                            Actualizar
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
            <Modal size="lg" show={showCancellation} onHide={handleCloseCancellation}>
                <Modal.Header closeButton>
                    <Modal.Title>Archivos de Baja</Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    <Form onSubmit={(e) => handleSubmit(e)} data-form-type="uploadFiles">
                        <div className="row ">
                            <div className="col-md-12">
                                <Form.Group controlId="formEndorsement">
                                    <div className="form-floating">
                                        <RenderDropzone
                                            onFilesChange={handleFileChange}
                                            fileName='Endoso'
                                            category="Endoso"
                                            files={formData.Endoso}
                                        />
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-12">
                                <Form.Group controlId="formCartaAntiguedad">
                                    <div className="form-floating">
                                        <RenderDropzone
                                            onFilesChange={handleFileChange}
                                            fileName='Carta_Antiguedad'
                                            category="Carta_Antiguedad"
                                            files={formData.Carta_Antiguedad}
                                        />
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-12">
                                <Form.Group controlId="formOtros">
                                    <div className="form-floating">
                                        <RenderDropzone
                                            onFilesChange={handleFileChange}
                                            fileName='Otros'
                                            category="Otros"
                                            files={formData.Otros}
                                        />
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-12 mt-3 mb-3">
                                <DateField
                                    label="Fecha de baja"
                                    name="policy_cancellation_date"
                                    value={dateCancellation}
                                    onChange={handleDateChange}
                                />
                            </div>

                        </div>
                        <Button variant="primary" type="submit">
                            Cargar Baja
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

        </div>
    );
};

export default ModalEditInsured;
