import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { Modal, Button, Form } from 'react-bootstrap';
import withReactContent from 'sweetalert2-react-content';
import PolicyHolderForm from './PolicyHolderForm';
import { pdfLogo } from "../global";
import { deletePDF } from '../api/PdfService';
import { deleteHolder, getHolderByOrganization, updateHolder } from '../api/PolicyHolderService';
import { infoGlobal } from '../global';
import { useDispatch, useSelector } from 'react-redux';
import SearchOrganization from './SearchAutoComplete/Organization/SearchOrganization';

const MySwal = withReactContent(Swal);

function PolicyHolderList() {
  const dispatch = useDispatch();
  const organization = useSelector((state) => state.organizations.data);
  const user = useSelector((state) => state.users.data);
  const [orgDatas, setOrgDatas] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [formData, setFormData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState('');
  const [policyHolders, setPolicyHolders] = useState([]);
  const [edit, setEdit] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [role] = useState('');
  const canDeleteOrCreate = user === 'admin' || user === 'executive';
  const categoryMap = {
    Acta_Constitutiva: 'Acta Constitutiva',
    CSF: 'C SituacionFiscal',
    Comprobante_Domicilio: 'Comprobante Domic',
    Identificacion: 'Identificacion',
    Otros: 'Otros'
  };

  // Función para filtrar PDFs por categoría
  const filterPdfsByCategory = (categoryKey) => {
    return formData.pdfs.filter((pdf) => pdf.category === categoryKey);
  };

  useEffect(() => {
    if (!organization.length) {
      infoGlobal.fetchOrganizations();
    } else {
      setOrgDatas(organization)
    }
    if (!user.length) {
      infoGlobal.fetchRole();
    }
  }, [organization, user, dispatch]);

  const fetchHolders = async ( selectedOrganization) => {
    try {
      const data = await getHolderByOrganization(selectedOrganization);
      setPolicyHolders(data.holders);
      setDataFetched(true);
    } catch (error) {
      console.error('Error fetching holders:', error);
    }
  };


  const handleOrganizationChange = async (selectedOrgId) => {
    try {
      setSelectedOrganization(selectedOrgId);
      fetchHolders(selectedOrgId);
    } catch (error) {
      console.error(error)
    }
  };

  const handleEdit = (id) => {
    setEditingId(id);
    const holderToEdit = policyHolders.find(holder => holder.id_policy_holder === id);
    setFormData({ ...holderToEdit });
    setShowModal(true);
  };

  const handleCancelEdit = () => {
    setEditingId(null);
    setFormData({});
    setEdit(false);
  };

  const handleEditSecundary = () => {
    setEdit(true);
  };

  const handleDeletePDF = async (id, pdfId) => {
    try {
      await deletePDF(pdfId);
      console.log(`Eliminar PDF ${pdfId} de la poliza ${id}`);
      alert("Actualizar las polizas");
    } catch (error) {
      console.error('Error deleting PDF:', error);
      MySwal.fire({
        icon: 'error',
        title: '¡Error!',
        footer: `<pre>${error.response.data.exception || 'Error desconocido'}</pre>`,

        text: 'Ha ocurrido un error al eliminar el PDF.',
      });
    }
  };

  const handleSaveEdit = async () => {
    try {
      await updateHolder(editingId, formData);
      setEditingId(null);
      setFormData({});
      MySwal.fire({
        icon: 'success',
        title: '¡Éxito!',
        text: 'Los datos se han actualizado correctamente.',
      }).then(fetch);
      setShowModal(false);
      fetchHolders();
    } catch (error) {
      console.error('Error updating Holders:', error);
      MySwal.fire({
        icon: 'error',
        footer: `<pre>${error.response.data.exception || 'Error desconocido'}</pre>`,

        title: '¡Error!',
        text: 'Ha ocurrido un error al actualizar los datos.',
      });
    }
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: '¿Quieres borrar este Contratante?',
      showDenyButton: true,
      confirmButtonText: 'Si',
      customClass: {
        actions: 'my-actions',
        cancelButton: 'order-1 right-gap',
        confirmButton: 'order-2',
        denyButton: 'order-3',
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteHolder(id);
          setPolicyHolders(prevHolders => prevHolders.filter(holder => holder.id_policy_holder !== id));
          MySwal.fire({
            icon: 'success',
            title: '¡Éxito!',
            text: 'El contratante se ha eliminado correctamente.',
          }).then(fetch);
        } catch (error) {
          console.error('Error deleting holder:', error);
          MySwal.fire({
            icon: 'error',
            title: '¡Error!',
            text: 'Ha ocurrido un error al eliminar El contratante.',
          });
        }
      }
    });
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleFieldChange = (fieldName, value) => {
    setFormData({ ...formData, [fieldName]: value });
  };

  const openPdf = (pdfUrl) => {
    const newWindow = window.open(pdfUrl);
    if (newWindow) {
      newWindow.onload = () => {
        newWindow.fetch(pdfUrl);
      };
    }
  };

  return (
    <div className='w-100 px-3 mx-auto'>
      <div className="col-md-6 mb-3">
      <h2> Contratante</h2>

      <SearchOrganization onSelect={handleOrganizationChange} />
      </div>
      {canDeleteOrCreate && <PolicyHolderForm updateData={fetchHolders} organizationForeign={selectedOrganization}/>}
      {dataFetched && (

        <table className="table">
          <thead>
            <tr>
              <th scope="col">Nombre</th>
              <th scope="col">RFC</th>
              <th scope="col">Direccion Fiscal</th>
              <th scope="col">Tipo de Entidad</th>
              <th scope="col">Acciones</th>

            </tr>
          </thead>
          <tbody>
            {policyHolders.map(holder => (
              <tr key={holder.id_policy_holder}>
                <td>{holder.business_name}</td>
                <td>{holder.rfc}</td>
                <td>{holder.fiscal_address}</td>
                <td>{holder.entityType}</td>
                <td>
                  <button className="btn btn-primary btn-sm me-1" onClick={() => handleEdit(holder.id_policy_holder)}>Editar</button>
                  {canDeleteOrCreate && (
                    <button className="btn btn-danger btn-sm" onClick={() => handleDelete(holder.id_policy_holder)}>Borrar</button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <Modal size="lg" show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Contratante</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                defaultValue={formData.business_name}
                onChange={(e) => handleFieldChange('business_name', e.target.value)}
                disabled={!edit}
              />
            </Form.Group>

            <Form.Group controlId="formRFC">
              <Form.Label>RFC</Form.Label>
              <Form.Control
                type="text"
                defaultValue={formData.rfc}
                onChange={(e) => handleFieldChange('rfc', e.target.value)}
                disabled={!edit}
              />
            </Form.Group>

            <Form.Group controlId="formFiscal_address">
              <Form.Label>Direccion Fiscal</Form.Label>
              <Form.Control
                type="text"
                defaultValue={formData.fiscal_address}
                onChange={(e) => handleFieldChange('fiscal_address', e.target.value)}
                disabled={!edit}
              />
            </Form.Group>
            <Form.Group controlId="formFiscal_address">
              <Form.Label>Tipo de Entidad</Form.Label>
              <Form.Control
                type="text"
                defaultValue={formData.entityType}
                onChange={(e) => handleFieldChange('entityType', e.target.value)}
                disabled={!edit}
              />
            </Form.Group>
            <Form.Group>
              {showModal && (
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      {Object.values(categoryMap).map((category, index) => (
                        <th key={index}>{category}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {Object.keys(categoryMap).map((categoryKey, index) => (
                        <td key={index}>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {filterPdfsByCategory(categoryKey).map((pdf, pdfIndex) => (
                              <div key={pdfIndex} style={{ marginBottom: '10px' }}>
                                <a href="#" onClick={() => openPdf(pdf.public_link)}>
                                  <img src={pdfLogo} alt="PDF" style={{ width: '28px', marginRight: '10px' }} />
                                  <span className="text-sm">{pdf.file_name}</span>
                                </a>
                              </div>
                            ))}
                          </div>
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {canDeleteOrCreate && (
            <div className="ms-auto">
              {!edit ? (
                <Button variant="primary" onClick={handleEditSecundary} className="me-2">
                  Editar
                </Button>
              ) : (
                <div>
                  <Button variant="success" onClick={handleSaveEdit} className="me-2">
                    Guardar
                  </Button>
                  <Button variant="danger" onClick={handleCancelEdit}>
                    Cancelar
                  </Button>
                </div>
              )}
            </div>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default PolicyHolderList;
