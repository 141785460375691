import axios from 'axios';
import { apiUrl } from '../global'

const token = localStorage.getItem('token');


// Método para obtener todas los Contratantes
export const getSubgroups = async () => {
  console.log("Entrando a getSubgroup");
  try {
    const response = await axios.get(`${apiUrl}/subgroups`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    });
    console.log("Repuesta del getSubgroup:", response.data)
    return response.data;
  } catch (error) {
    console.error('Error fetching getSubgroup:', error);
    throw error;
  }
};

// Método para agregar un nuevo Contratante

export const postSubgroup = async (data) => {
  try {
    console.log("Entrando a postSubgroup: ", data);

    const response = await axios.post(`${apiUrl}/subgroup`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
      },
    });
    console.log("Repuesta del postSubgroup:", response.data)
    return response.data;
  } catch (error) {
    console.error('Error adding subgroup:', error);
    throw error;
  }
};
// Método para actualizar subgrupo existente
export const updateSubgroup = async (id, data) => {
  console.log("Entrando a updateSubgroup: ", id);
  console.log("Entrando a updateSubgroup: ", data);

  try {
    const response = await axios.put(`${apiUrl}/subgroup/${id}`, data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    console.log("Respuesta:", response);
    return response.data;

  } catch (error) {
    console.error('Error updating subgroup:', error);
    throw error;
  }
};
export const deleteSubgroup = async (id) => {
  try {
    const response = await axios.delete(`${apiUrl}/subgroup/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    console.log("Respuesta:", response);
    return response.data;

  } catch (error) {
    console.error('Error deleting subgroup:', error);
    throw error;
  }
};
export const getSubgroupByPolicyId = async (id_policy) => {
  console.log("Entrando a getSubgroupByPolicyId");
  try {
    const response = await axios.get(`${apiUrl}/getSubgroupsById/${id_policy}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    });
    console.log("Repuesta del getSubgroupByPolicyId:", response.data)
    return response.data;
  } catch (error) {
    console.error('Error fetching getSubgroupByPolicyId:', error);
    throw error;
  }
};
export const attachPDFSubgroup = async (id, data) => {
  console.log("Entrando a attachPDFSubgroup: ", id);
  console.log("Entrando a attachPDFSubgroup: ", data);
  try {
    const response = await axios.post(`${apiUrl}/attachPDF/subgroup/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
      },
    });
    console.log('respuesta del attachPDFSubgroup', response.data);
    return response.data;
  } catch (error) {
    console.error('Error attachPDFSubgroup:', error);
    throw error;
  }
};