import React from 'react';
import { Modal, Button, Form, Table, Accordion } from 'react-bootstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { attachFilesInsured, updateInsured } from '../../../api/InsuredService';
import { deletePDF } from '../../../api/PdfService';
import { pdfLogo } from '../../../global';
import { RenderDropzone } from '../../../features/pdfs/functionsPdf';
import DateField from '../../Helpers/DateField';
import { format } from 'date-fns';

const ModalAttachFiles = ({ showModal, handleClose, insured, updateList }) => {
    console.log('asegurado seleccionado', insured)
    const MySwal = withReactContent(Swal);
    const [formData, setFormData] = React.useState(insured);
    const [showCancellation, setShowCancellation] = React.useState(false);
    const [dateCancellation, setDateCancellation] = React.useState(new Date().toString());

    React.useEffect(() => {
        setFormData(insured);
    }, [insured]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        console.log('name', value)
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await attachFilesInsured(insured.id_insured, formData);
            MySwal.fire({
                title: '¡Éxito!',
                text: 'El Asegurado se ha actualizado correctamente.',
                icon: 'success',
                confirmButtonText: 'Cerrar'
            }).then(() => {
                updateList()
                setShowCancellation(false)
            });
            handleClose();
        } catch (error) {
            MySwal.fire({
                icon: 'error',
                title: '¡Error!',
                text: 'Ha ocurrido un error al actualizar el Asegurado.',
                footer: `<pre>${error.response.data.exception || 'Error desconocido'}</pre>`,
            });
        }
    };

    const handleDeletePDF = async (pdfId) => {
        try {
            const result = await MySwal.fire({
                title: '¿Eliminar PDF?',
                text: 'Esta acción no se puede deshacer.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Eliminar',
                cancelButtonText: 'Cancelar',
            });

            if (result.isConfirmed) {
                await deletePDF(pdfId);
                updateList();
                handleClose();

                MySwal.fire({
                    icon: 'success',
                    title: 'PDF eliminado',
                    timer: 1500,
                    showConfirmButton: true,
                    onClose: () => console.log('El modal se cerró'),
                });
            }
        } catch (error) {
            MySwal.fire({
                icon: 'error',
                title: 'Error al eliminar',
                text: 'No se pudo eliminar el PDF.',
                footer: `<pre>${error.response?.data?.exception || 'Error desconocido'}</pre>`,
            });
        }
    };

    const openPdf = (pdfUrl) => {
        const newWindow = window.open(pdfUrl);
        if (newWindow) {
            newWindow.onload = () => {
                newWindow.fetch(pdfUrl, {
                });
            };
        }
    };
    const handleFileChange = (updatedFiles, category) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [category]: updatedFiles
        }));
        console.log(category, updatedFiles);
    };

    return (
        <div>

            <Modal size="lg" show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Archivos del asegurado</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form onSubmit={(e) => handleSubmit(e)} data-form-type="uploadFiles">
                        <div className="row ">
                            <div className="col-md-6">
                                <Form.Group controlId="formEndorsement">
                                    <div className="form-floating">
                                        <RenderDropzone
                                            onFilesChange={handleFileChange}
                                            fileName='Certificado'
                                            category="Certificado"
                                            files={formData.Endoso}
                                        />
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group controlId="formEndorsement">
                                    <div className="form-floating">
                                        <RenderDropzone
                                            onFilesChange={handleFileChange}
                                            fileName='Credencial'
                                            category="Credencial"
                                            files={formData.Endoso}
                                        />
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group controlId="formEndorsement">
                                    <div className="form-floating">
                                        <RenderDropzone
                                            onFilesChange={handleFileChange}
                                            fileName='Endoso'
                                            category="Endoso"
                                            files={formData.Endoso}
                                        />
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group controlId="formCartaAntiguedad">
                                    <div className="form-floating">
                                        <RenderDropzone
                                            onFilesChange={handleFileChange}
                                            fileName='Carta Antiguedad'
                                            category="Carta_Antiguedad"
                                            files={formData.Carta_Antiguedad}
                                        />
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group controlId="formEndorsement">
                                    <div className="form-floating">
                                        <RenderDropzone
                                            onFilesChange={handleFileChange}
                                            fileName='Reconocimiento de Antiguedad'
                                            category="Reconocimiento_Antiguedad"
                                            files={formData.Endoso}
                                        />
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group controlId="formOtros">
                                    <div className="form-floating">
                                        <RenderDropzone
                                            onFilesChange={handleFileChange}
                                            fileName='Otros'
                                            category="Otros"
                                            files={formData.Otros}
                                        />
                                    </div>
                                </Form.Group>
                            </div>
                            <Table striped bordered hover size="sm" responsive >
                                <thead>
                                    <tr>
                                        <th>PDF</th>
                                        <th>Nombre</th>
                                        <th>Categoría</th>
                                        <th>Fecha</th>
                                        <th>Eliminar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {insured.pdfs.map((pdf) => (
                                        <tr key={pdf.id}>
                                            <td>
                                                <a href="#" onClick={() => openPdf(pdf.public_link)}>
                                                    <img src={pdfLogo} alt="PDF" style={{ width: '28px', marginRight: '10px' }} />
                                                </a>
                                            </td>
                                            <td>{pdf.file_name}</td>
                                            <td>{pdf.category}</td>
                                            <td>{new Date(pdf.created_at).toLocaleDateString()}</td>
                                            <td>
                                                <Button
                                                    variant="danger"
                                                    size="sm"
                                                    onClick={() => handleDeletePDF(pdf.id, pdf.pdfable_id)}
                                                >
                                                    Eliminar
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>    
                        </div>
                        <Button variant="success" type="submit">
                            Cargar Archivos
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

        </div>
    );
};

export default ModalAttachFiles;
