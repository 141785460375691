import React, { useState, useRef, useEffect } from 'react';
import { Accordion, Button, Modal, Table } from 'react-bootstrap';
import { pdfLogo } from '../../global';
import { deletePDF } from '../../api/PdfService';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { attachPDFPolicy, getPdfsPolicy } from '../../api/policyService';

const ModalEditPolicyFiles = ({ show, close, data }) => {
    const [pdfData, setPdfData] = useState([]);
    const [pdfsPolicie, setPdfsPolicie] = useState(data?.pdfs);
    const fileInputRef = useRef(null); //Ref del input
    const MySwal = withReactContent(Swal);
    const categories = [
        'Caratula',
        'Endoso',
        'Certificado',
        'Credencial_Digital',
        'Recibo',
        'Factura',
        'Presentacion',
        'Otros',
    ];
    const handleFileChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        const updatedPdfData = selectedFiles.map((file) => ({
            file,
        }));

        // Actualizamos el estado con los archivos seleccionados
        setPdfData((prevPdfData) => [...prevPdfData, ...updatedPdfData]);

        // Reseteamos el valor del input
        if (fileInputRef.current) {
            fileInputRef.current.value = ""; // Limpiar el input de archivos
        }
    };
    const handleCategoryChange = (index, selectedCategory) => {
        const updatedPdfData = [...pdfData];
        updatedPdfData[index].category = selectedCategory;
        setPdfData(updatedPdfData);
    };

    const handleRemoveFile = (index) => {
        const updatedPdfData = [...pdfData];
        updatedPdfData.splice(index, 1); // Eliminar el archivo en el índice dado
        setPdfData(updatedPdfData);
    };
    const handleDeletePDF = async (pdfId) => {
        try {
            const result = await MySwal.fire({
                title: '¿Eliminar PDF?',
                text: 'Esta acción no se puede deshacer.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Eliminar',
                cancelButtonText: 'Cancelar',
            });

            if (result.isConfirmed) {
                await deletePDF(pdfId);
                handleUpdatePdfs();

                MySwal.fire({
                    icon: 'success',
                    title: 'PDF eliminado',
                    timer: 1500,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            MySwal.fire({
                icon: 'error',
                title: 'Error al eliminar',
                text: 'No se pudo eliminar el PDF.',
                footer: `<pre>${error.response?.data?.exception || 'Error desconocido'}</pre>`,
            });
        }
    };
    const openPdf = (pdfUrl) => {
        const newWindow = window.open(pdfUrl);
        if (newWindow) {
            newWindow.onload = () => {
                newWindow.fetch(pdfUrl, {
                });
            };
        }
    };
    const handleUpdatePdfs = async () => {
        const response = await getPdfsPolicy(data?.id_policy)
        setPdfsPolicie(response?.pdfs)
        console.log('res update', response.pdfs)
    }
    const handleSubmit = async () => {
        try {
            // Crear un objeto FormData
            const formData = new FormData();

            // Agregar los archivos organizados por categorías al FormData
            pdfData.forEach(({ category, file }) => {
                if (category && file) {
                    formData.append(`${category}[]`, file); // Agrupamos los archivos bajo su categoría
                }
            });

            // Leer el contenido de FormData
            formData.forEach((value, key) => {
                console.log(key, value);
            });

            // Enviar el FormData a la API
            const response = await attachPDFPolicy(data?.id_policy, formData);
            console.log('response', response);
            handleUpdatePdfs();
            setPdfData([])
        } catch (error) {
            console.error('error', error);
        }
    };

    return (
        <div>
            <Modal show={show} onHide={close} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Archivos de la Póliza:</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <h5>Agregar nuevos Archivos</h5>

                    {/* Input para seleccionar archivos */}
                    <div className="mb-3">
                        <input
                            ref={fileInputRef}
                            className="form-control"
                            type="file"
                            accept=".pdf"
                            multiple
                            onChange={handleFileChange}
                        />
                    </div>
                    {pdfData?.length > 0 && (
                        <Button variant="success" onClick={handleSubmit}>
                            Cargar Archivo(s)
                        </Button>
                    )}
                    {/* Visualización de archivos seleccionados */}
                    {pdfData && (
                        <div>
                            {pdfData?.map((pdf, index) => (
                                <div key={index} className="mb-3">
                                    <div className="d-flex align-items-center">
                                        {/* Nombre del archivo */}
                                        <p
                                            className="m-0 me-3"
                                            style={{
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                width: '70%',
                                            }}
                                        >
                                            {pdf.file.name}
                                        </p>

                                        {/* Selector de categoría */}
                                        <select
                                            value={pdf.category}
                                            className="form-select me-3"
                                            onChange={(e) => handleCategoryChange(index, e.target.value)}
                                        >
                                            <option value="">Selecciona</option>
                                            {categories.map((category, i) => (
                                                <option key={i} value={category}>
                                                    {category}
                                                </option>
                                            ))}
                                        </select>

                                        {/* Botón eliminar archivo */}
                                        <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={() => handleRemoveFile(index)}
                                        >
                                            Eliminar
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                    {/* Tabla con archivos de la póliza */}
                    <h5>Archivos Actuales</h5>

                    <Table striped bordered hover size="sm" responsive>
                        <thead>
                            <tr>
                                <th>PDF</th>
                                <th>Nombre</th>
                                <th>Categoria</th>
                                <th>Fecha</th>
                                <th>Eliminar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {pdfsPolicie?.length > 0 ? (
                                pdfsPolicie.map((pdf) => (
                                    <tr key={pdf.id}>
                                        <td>
                                            <a href="#" onClick={() => openPdf(pdf.public_link)}>
                                                <img
                                                    src={pdfLogo}
                                                    alt="PDF"
                                                    style={{ width: '28px', marginRight: '10px' }}
                                                />
                                            </a>
                                        </td>
                                        <td>{pdf.file_name}</td>
                                        <td>{pdf.category}</td>
                                        <td>{new Date(pdf.created_at).toLocaleDateString()}</td>
                                        <td>
                                            <Button
                                                variant="danger"
                                                size="sm"
                                                onClick={() => handleDeletePDF(pdf.id, pdf.pdfable_id)}
                                            >
                                                Eliminar
                                            </Button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5">No hay PDFs asociados.</td>
                                </tr>
                            )}

                        </tbody>
                    </Table>
                </Modal.Body>

                {/* Botones del modal */}
                <Modal.Footer>
                    <Button variant="secondary" onClick={close}>
                        Cancelar
                    </Button>

                </Modal.Footer>
            </Modal>


        </div>
    );
}
export default ModalEditPolicyFiles