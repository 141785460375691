import React, { useEffect, useState } from 'react';


function UsersComponent({ show, handleClose, endorsementsData, organization }) {
  const [selectedEndoso, setSelectedEndoso] = useState(); 

  return (
    <div>
        
    </div>
  );

};

export default UsersComponent;
